var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import close_icon from "../../../assets/img/close_modal_icon.png";
import style from "./modalReg.module.css";
import { useMediaQuery } from "react-responsive";
import { ModalRegForm } from "../../Helpers/Forms/ModalRegForm/ModalRegForm";
import { useSelector } from "react-redux";
export var ModalReg = function (_a) {
    var onClose = _a.onClose;
    var isMobile = useMediaQuery({
        query: "(max-width: 700px)",
    });
    var logo = useSelector(function (state) { var _a, _b; return (_b = (_a = state.menu.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.logo; });
    var handleCloseModal = function () {
        onClose();
        // dispatch(setActive(false));
    };
    return (_jsx("div", __assign({ onClick: function (e) { return e.stopPropagation(); }, className: style.modalReg }, { children: _jsx("div", __assign({ className: style.scrollableContent }, { children: _jsxs("div", __assign({ className: style.content }, { children: [_jsx("div", __assign({ className: style.modalRegLogo }, { children: isMobile ? (_jsx("div", __assign({ onClick: function () { return handleCloseModal(); }, className: style.modalRegClose }, { children: _jsx("img", { className: style.modalRegCloseIcon, src: close_icon, alt: "close icon" }) }))) : null })), _jsx(ModalRegForm, {}), isMobile ? null : (_jsx("img", { onClick: function () { return handleCloseModal(); }, className: style.modalRegCloseIcon, src: close_icon, alt: "close icon" }))] })) })) })));
};
