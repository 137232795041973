// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UOPBB3lAZjwmKMYXRFJ1 {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 34px 22px 29px 33px;
    width: 424px;
    /* height: 189px; */
    background-color: #fff;
    border-radius: 12px;
    opacity: 1;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.mMddxSE2p5mYtwQXMf9z {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.YBqXeBNbqffXUUP6EMFW {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.Zn8FTtx4aNfxwu1pRRZT {
    color: #000;
    font-size: 16px;
}

@media (max-width: 880px) {
    .YBqXeBNbqffXUUP6EMFW {
        margin-left: 85%;
    }
}

@media (max-width: 480px) {
    .UOPBB3lAZjwmKMYXRFJ1 {
        width: 90%;
    }
    .YBqXeBNbqffXUUP6EMFW {
        margin-left: 83%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalStartupContacts/modalStartupContacts.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,4BAA4B;IAC5B,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".startupContacts {\r\n    position: fixed;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 34px 22px 29px 33px;\r\n    width: 424px;\r\n    /* height: 189px; */\r\n    background-color: #fff;\r\n    border-radius: 12px;\r\n    opacity: 1;\r\n    z-index: 999;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    overflow: hidden;\r\n}\r\n\r\n.content {\r\n    height: 100%;\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    position: relative;\r\n}\r\n\r\n.btnClose {\r\n    background: none;\r\n    border: none;\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n}\r\n\r\n.startupContacts_content {\r\n    color: #000;\r\n    font-size: 16px;\r\n}\r\n\r\n@media (max-width: 880px) {\r\n    .btnClose {\r\n        margin-left: 85%;\r\n    }\r\n}\r\n\r\n@media (max-width: 480px) {\r\n    .startupContacts {\r\n        width: 90%;\r\n    }\r\n    .btnClose {\r\n        margin-left: 83%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startupContacts": `UOPBB3lAZjwmKMYXRFJ1`,
	"content": `mMddxSE2p5mYtwQXMf9z`,
	"btnClose": `YBqXeBNbqffXUUP6EMFW`,
	"startupContacts_content": `Zn8FTtx4aNfxwu1pRRZT`
};
export default ___CSS_LOADER_EXPORT___;
