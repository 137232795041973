var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch } from '@src/redux/store';
import styles from './modalStartupContacts.module.css';
import { setActive } from '@src/redux/modalReducer';
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from 'react-redux';
function ModalStartupContacts(_a) {
    var isActive = _a.isActive;
    var dispatch = useAppDispatch();
    var contacts = useSelector(function (state) { return state.modal.contactContent; });
    var handleCloseFilter = function () {
        dispatch(setActive(false));
    };
    return isActive ? (_jsxs("div", __assign({ className: styles.startupContacts }, { children: [_jsx("button", __assign({ className: styles.btnClose, onClick: function () { return handleCloseFilter(); } }, { children: _jsx(CloseIcon, { fontSize: "large", sx: { width: "24px", height: "24px", cursor: 'pointer' } }) })), _jsx("div", __assign({ className: styles.content }, { children: contacts ? _jsx("div", { className: styles.startupContacts_content, dangerouslySetInnerHTML: { __html: contacts } }) : 'Контакты не указаны' }))] }))) : null;
}
export default ModalStartupContacts;
